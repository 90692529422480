function getCookie(name) {
	let value = `; ${document.cookie}`;
	let parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

document.addEventListener('DOMContentLoaded', function() {
	console.log('Breaking news.');

	let page_article = $('body').data('article-id');
	let breaking_article = $('.breaking-article').data('article-id');
	let cookie_article = getCookie(`breaking-article-seen-${breaking_article}`);

	if (cookie_article == breaking_article) {
		console.log('Cookie set. Do not show.');
		return;
	}

	if (page_article == breaking_article) {
		console.log('On the breaking article page.');
		return;
	}

	setTimeout(function() {
		console.log('Unhide breaking.');
		$('.breaking-article.hidden').removeClass('hidden');
	}, 500);

	$('.breaking-close').on('click', function() {
		$('.breaking-article').addClass('hidden');
		document.cookie = `breaking-article-seen-${breaking_article}=${breaking_article};`;
	});
});
